import { StylesProvider } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useMemo } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import './App.scss';
import ErrorAlert from "./components/Alerts/ErrorAlert/ErrorAlert";
import SuccessAlert from "./components/Alerts/SuccessAlert/SuccessAlert";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import ProtectedRoute from "./components/RouteComponents/ProtectedRoute";
import SessionRoute from "./components/RouteComponents/SessionRoute";
import Sidebar from "./components/Sidebar/Sidebar";
import useScreenSize from "./hooks/useScreenSize";
import useStoreSessionSelector from "./hooks/useStoreSessionSelector";
import Login from './pages/login/Login';
import theme from "./services/theme";
import { Box } from '@mui/material';
import { privateRoutes } from './configs/privateRoutes';


const App = () => {

  const device = useScreenSize();
  // @ts-ignore
  const mainTheme = useMemo(() => createTheme(theme()), []);

  const session = useStoreSessionSelector();
  const [collapsed, setCollapsed] = React.useState(false);

  const isSidebarCollapsed = (event: boolean) => setCollapsed(event);

  return (
    <>
      <Box
        alignItems={"center"}
        flexDirection={device !== "mobile" ? "row" : "inherit"}
        display={device !== "mobile" ? "flex" : "inherit"}
        className="dashboard"
      >
        <StylesProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <ErrorAlert />
            <SuccessAlert />
            <BrowserRouter>
              {session.token && (
                  device !== "mobile" ? (
                    <Box sx={{p: 0}}>
                      {(
                        <Box className="sideBox">
                          <Sidebar isSidebarCollapsed={isSidebarCollapsed} />
                        </Box>
                      )}
                    </Box>) : <MobileMenu />
              )}

              <Box className="contentBox">
                <Switch>
                  <Redirect exact from="/" to="/users" />
                  <SessionRoute exact
                    path="/login"
                    token={session.token}
                    component={Login} />

                    {privateRoutes.map((route, index) => 
                      <ProtectedRoute 
                        key={index}
                        exact 
                        path={route.path} 
                        component={route.component} 
                        token={session.token}/>)}

                </Switch>
              </Box>
            </BrowserRouter>
          </ThemeProvider>
        </StylesProvider>
      </Box>
    </>
  );
}

export default App;

import Api from "./apiClient";

export default class ApiRequests extends Api{

    constructor(protected resource?: string) {
        super();
    }
    
  async postResource(payload: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}`, payload, "POST");
  }

  async postSpecificResource(suffix: string, payload: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}/${suffix}`, payload, "POST");
  }

  async getAllResources(payload?: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}`, payload, "GET");
  }

  async getSpecificResource(id: string, payload?: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}/${id}`, payload, "GET");
  }

  async deleteResource(id: any, payload?: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}/${id}`, payload, "DELETE");
  }

  async deleteManyResource(payload: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}`, payload, "DELETE");
  }

  async deleteSpecificResource(id: string, payload?: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}/${id}`, payload, "DELETE");
  }

  async patchResource(id: string, payload: any) {
    return await this.doRequest(`${this.baseUrl}/${this.resource}/${id}`, payload, "PATCH");
  }
    
}
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import React from 'react';
import AdsService from '../../../services/Entities/AdsService';
import UploaderService from '../../../services/Entities/UploaderService';
import AccommodationPhotoAlbum from '../Accomodation/AccommodationPhotoAlbum/AccommodationPhotoAlbum';
import AccomodationDetailsLoading from '../Accomodation/AccomodationDetailsLoading';
import "../../PageComponents/Accomodation/AddEditAccomodationForm.scss";
import CustomButton from '../../CustomButton/CustomButton';

const AddEditAdsForm = ({
    object,
    setApiPhotos,
    setObject,
    setPatchObject,
    patchObject,
    apiPhotos,
    patchPhotos,
    setPatchPhotos,
    handleInputChange,
    loading,
    setSubmitted,
    submitted,
    formState
}: any) => {
    const [photosLoading, setPhotosLoading] = React.useState<boolean>(false);
    const [fileUrlArray, setFileUrlArray] = React.useState<any[]>([]);
    const uploader = React.useRef<any>();
    const uploaderClient = new UploaderService();

    const handleFileUpload = (e: any) => {
        let arr = Array.from(e.target.files);
        uploadImages(arr);
    }

    React.useEffect(() => {
        if (submitted) {
            setFileUrlArray([]);
        }
    }, [submitted]);

    React.useEffect(() => console.log("fileUrlArray", fileUrlArray), [fileUrlArray])

    const uploadImages = (files: any[]) => {
        let formData = new FormData;
        files.forEach((file) => formData.append("image", file as Blob));
        setPhotosLoading(true);
        uploaderClient
            .postSpecificResource("images", formData)
            .then((res) => {
                let tempfileUrlArray = [...fileUrlArray];
                res.data.data.forEach((photo: any) => tempfileUrlArray.push({ file_url: photo.file_url }));
                setPatchPhotos(tempfileUrlArray);
                handleInputChange(tempfileUrlArray);
                setFileUrlArray(tempfileUrlArray);
                setSubmitted(false);
            })
            .finally(() => setPhotosLoading(false))
    }

    return (
        <form>
            <Typography variant="h3">Details</Typography>
            {loading ? <AccomodationDetailsLoading/> : (
            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                    <TextField fullWidth
                        label="Title"
                        name="title"
                        value={object ? object.title : ""}
                        onChange={(e) => handleInputChange(e)}
                        sx={{my: 2}} />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                    <TextField fullWidth
                        label="Label"
                        name="label"
                        value={object ? object.label : ""}
                        onChange={(e) => handleInputChange(e)}
                        sx={{my: 2}} />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={6} xs={12} sx={{display: "flex"}}>
                    <FormControlLabel
                    control={
                        <Switch checked={object?.is_enabled} 
                                onChange={(e) => handleInputChange(e)} 
                                name="is_enabled" 
                                sx={{alignSelf: "center"}}/>
                    }
                    label="Enabled"
        />
                </Grid>
            </Grid>
            )}
            <hr />

            <div
                style={{ display: "flex", clear: "both" }}>
                <Typography variant="h3" sx={{ alignSelf: "center", marginRight: "10px" }}>Photos</Typography>
                <CustomButton
                    className="iconButton"
                    onClick={() => uploader.current.click()}>
                    <FontAwesomeIcon className="icon"
                        icon={faPaperclip}/>
                </CustomButton>
            </div>
            <input
                name="flyer"
                type="file"
                ref={uploader}
                multiple
                hidden
                accept="image/*"
                onChange={(e) => handleFileUpload(e)} />
                {photosLoading  ? <CircularProgress/> : <AccommodationPhotoAlbum patchPhotos={patchPhotos}
                                                            apiPhotos={apiPhotos}
                                                            setPatchPhotos={setPatchPhotos}
                                                            setApiPhotos={setApiPhotos}
                                                            object={object}
                                                            submitted={submitted} />}
            
        </form>
    )
}

export default AddEditAdsForm

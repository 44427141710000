import React from "react";
import "./GoBackButton.scss";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../../hooks/useScreenSize";

const GoBackButton = () => {
  const history = useHistory();
  const device = useScreenSize();
  const goBack = () => {
    // @ts-ignore
    history.push('/');
  };
  return (
    <button className="goBackBtn textPrimary px-3 py-1" onClick={goBack}>
      <FontAwesomeIcon icon={faChevronLeft} color="#fff" className="icon" style={{marginRight: "15px"}}/>
      {device === "laptop" && "Go Back"}
    </button>
  );
};

export default GoBackButton;

const useScreenSize = () => {
    let screenWidth = window.screen.width;
        if (screenWidth <= 768 && screenWidth >= 425) {
            return "tablet";
        }
        else if (screenWidth < 425) {
            return "mobile";
        }
        else if (screenWidth > 768) {
            return "laptop";
        }
}

export default useScreenSize;
import axios from "axios";
import { store } from "../store";

const baseUrl = process.env.REACT_APP_API_URL ?? "";

export const axiosFactory = () => {
    let currentState: any = store.getState();

    const axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
            Authorization: `Bearer ${currentState?.session?.token}`,
            "Content-Type": "application/json"
        }
    });

    return axiosInstance;

}

export default axiosFactory;
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, Grid, Typography } from '@mui/material'
import React from 'react'
import { CountCard } from '../../../interfaces/CountCard'

const DashboardCountCard = ({label, count, icon}: CountCard) => {
  return (
    <Card
    sx={{
        width: "100%",
        p: 1
    }}>
        <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <FontAwesomeIcon icon={faHome}/>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Typography variant='h3'>1500</Typography>
                <Typography variant='h4'>Hotel</Typography>
            </Grid>
        </Grid>
    </Card>
  )
}

export default DashboardCountCard
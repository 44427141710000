import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import placeholderPic from "../../../assets/404.gif";
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import GenericTable from '../../../components/GenericTable/GenericTable';
import GenericModal from '../../../components/Modal/Modal';
import { facilitiesConfig } from '../../../configs/facilities';
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import { Facility } from '../../../interfaces/Facility';
import FacilitiesService from '../../../services/Entities/FacilitiesService';
import AddEditFacilities from '../AddEditFacilities/AddEditFacilities';

const FacilitiesTableView = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facility, setFacility] = useState<Facility>({ name: "", icon_url: "" });
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const session = useStoreSessionSelector();
  const history = useHistory();
  const facilitiesClient = new FacilitiesService();

  const handleSearch = (value: string) => {
    if (value && value !== "") {
      setLoading(true);
      const payload = {
        query: {
          'filters[code]': value,
          page: page,
          pageSize: rowsPerPage,
          noAds: true
        }
      };
      facilitiesClient
        .getAllResources(payload)
        .then((response) => {
          setList(response.data.data);
          setTotal(response.data.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      fetchData();
    }
  };

  const fetchData = () => {
    setLoading(true);
    const payload = {
      token: session.token,
      query: {
        page: page,
        pageSize: rowsPerPage,
        noAds: true
      }
    };
    facilitiesClient
      .getAllResources(payload)
      .then((response) => {
        setList(response.data.data);
        setTotal(response.data.total);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteResource = () => {
    setLoading(true);
    const payload = {
      data: selectedRows
    };
    facilitiesClient
      .deleteManyResource(payload)
      .then(() => {
        fetchData();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  React.useEffect(fetchData, [page, rowsPerPage]);

  const navigateToAddPage = () => {
    setModal(true);
  }

  const rowTriggerAction = (row: any) => {
    setFacility({ ...row });
    setModal(true);
  }

  const handlePageChange = (e: any, value: any) => {
    setPage(value);
  };

  const handleRowsChange = (e: { target: { value: string; }; }) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };


  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };

  return (
    <Box sx={{p: 0}}>
      <GenericModal
        size="medium"
        state={modal}
        onClose={() => setModal(false)}>
        <AddEditFacilities
          onClose={() => setModal(false)}
          facility={facility}
          fetchAll={fetchData} />
      </GenericModal>
      <GenericHeader
        onSearch={handleSearch}
        addResourceHandler={navigateToAddPage}
        deleteResourceHandler={deleteResource}
        model="facility" />
      <Box sx={{p: 4}}>
        <Typography variant="h1" sx={{mb: 4, color: "#333"}}>Facilities</Typography>
        <GenericTable
          list={list}
          totalCount={total}
          onSelectedChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          page={page}
          loading={loading}
          rowsPerPage={rowsPerPage}
          onRowsChange={handleRowsChange}
          onPageChange={handlePageChange}
          image={placeholderPic}
          tableConfig={facilitiesConfig}
          model="facility"
          passRow={rowTriggerAction}
        />
      </Box>
    </Box>
  )
}

export default FacilitiesTableView

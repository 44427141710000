import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, InputAdornment, TextField } from '@mui/material';
import React from 'react';

interface PasswordFieldsInterface{
    password: string,
    setPassword: any,
    tempPassword: string,
    setTempPassword: any
}

const PasswordFields: React.FC<PasswordFieldsInterface> = ({password, setPassword, tempPassword, setTempPassword}) => {
    const [showOriginalPassword, setShowOriginalPassword] = React.useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = React.useState(false);
    return (
        <React.Fragment>
            <Box sx={{my: 3}}>
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <span
                                className="pointer"
                                onClick={() => setShowOriginalPassword(!showOriginalPassword)}
                            >
                                {showOriginalPassword ? <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: "pointer", color: "#cb3440" }} /> : <FontAwesomeIcon icon={faEye} style={{ cursor: "pointer", color: "#cb3440" }} />}
                            </span>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                label="Password"
                name="password"
                type={showOriginalPassword ? "text" : "password"}
                value={tempPassword ? tempPassword : ""}
                onChange={(e) => setTempPassword(e.target.value)}
            />
            </Box>
            <Box sx={{my: 3}}>
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <span
                                className="pointer"
                                onClick={() => setShowConfirmationPassword(!showConfirmationPassword)}
                            >
                                {showConfirmationPassword ? <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: "pointer", color: "#cb3440" }} /> : <FontAwesomeIcon icon={faEye} style={{ cursor: "pointer", color: "#cb3440" }} />}
                            </span>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                label="Confirm Password"
                name="password"
                type={showConfirmationPassword ? "text" : "password"}
                value={password ?? ""}
                onChange={(e) => setPassword(e.target.value)}
            />
            </Box>
            {tempPassword !== password && <p className="text-danger mb-0"><i>Both passwords must be matching!</i></p>}
        </React.Fragment>
    )
}

export default PasswordFields

import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { Box, CircularProgress, TextField } from '@mui/material';

const MapMarker = ({ text }: any) => <div><FontAwesomeIcon size='3x' icon={faMapMarker} color='#cb3440' /></div>

const CustomMap = ({ object, setObject, patchObject, setPatchObject, loading }: any) => {

  const defaultCenter = { lng: 20.111127, lat: 32.089986 };

  const onSelectMapSpot = (e: GoogleMapReact.ClickEventValue) => {
    setObject({ ...object, longitude: e.lng, latitude: e.lat });
    setPatchObject({ ...patchObject, longitude: e.lng, latitude: e.lat });
  }

  return (
    <>
    {!loading ? (
      <>
      <Box
      sx={{ height: "50%",
            mb: 4,
            mt: 3}}>
        <TextField
          type='number'
          name='longitude'
          sx={{mx: 2}}
          value={object?.longitude ? object.longitude : ""}
          label='Longitude'
          onChange={(e) => {
            setObject({ ...object, longitude: +e.target.value });
            setPatchObject({...patchObject, longitude: +e.target.value})
            }} />
        <TextField
          type='number'
          sx={{mx: 2}}
          value={object?.latitude ? object.latitude : ""}
          name='latitude'
          label='Latitude'
          onChange={(e) => {
            setObject({ ...object, latitude: +e.target.value });
            setPatchObject({...patchObject, latitude: +e.target.value});
            }} />
      </Box>
      <Box style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyD6AUPIR0eIiGldIIo0b06uqLxlZDyQh-I", language: "ar", region: "ly " }}
          center={object?.longitude ? { lng: object?.longitude, lat: object?.latitude } : defaultCenter}
          defaultCenter={defaultCenter}
          defaultZoom={17}
          onClick={(e) => onSelectMapSpot(e)}
        >
          {(object?.longitude && object?.latitude) && <MapMarker
            lng={object.longitude}
            lat={object.latitude}
            text="My Marker"
          />}
        </GoogleMapReact>
      </Box>
      </>) : <CircularProgress/>}
    </>
  );
}

export default CustomMap;
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { Flight } from '../../../interfaces/Flight';
import FlightService from '../../../services/Entities/FlightService';
import { showSuccessAlert } from '../../../store/actions/alerts';
import "./UploadFlightCSV.scss";

const UploadFlightCSV = ({refreshTable, setModal, onClose}: any) => {

    const uploader = React.useRef<any>();
    const [fileSelected, setFileSelected] = React.useState<any>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const flightService = new FlightService();

    const submitCSV = () => {
        setLoading(true);
        let formData = new FormData;
        formData.append("file", fileSelected as Blob)
        flightService
            .postSpecificResource("csv", formData)
            .then((res) => {
                showSuccessAlert("File Posted Successfully!");
                createNewFlights(res.data.final_data);
            })
            .finally(() => setLoading(false))
    }

    const createNewFlights = (flights: Flight[]) => {
        setLoading(true);
        flights.map((flight: Flight) => {
            flight.airline_id = "37854bf5-5bcd-4491-bec9-b7ba55air004";
            flight.status = "ON TIME";
        });
        flightService
            .postResource(flights)
            .then(() => {
                showSuccessAlert("Rows Added Successfully!");
                setModal(false);
                refreshTable();
            })
            .finally(() => setLoading(false))
    }

    return (
        <React.Fragment>
            <Typography variant="h4" >Upload a CSV</Typography>
            <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <FontAwesomeIcon className="uploadIcon" 
                                 icon={faUpload}
                                 id="uploadIcon" 
                                 onClick={() => uploader.current.click()}
                                 onDragOver={() => document.getElementById("uploadIcon")?.classList.add("dragOverIcon")}
                                 onDragLeaveCapture={() => document.getElementById("uploadIcon")?.classList.remove("dragOverIcon")}
                                 onDrop={(e: any) => {
                                     e.preventDefault();
                                     setFileSelected(e.dataTransfer.files[0])
                                     }}/>
                <input 
                    ref={uploader}
                    hidden
                    type="file"
                    accept=".csv"
                    onChange={(e: any) => setFileSelected(e.target.files[0])}/>
                    {fileSelected && <p>File Selected: {fileSelected?.name}</p>}
                <Button color='primary' onClick={submitCSV}>{loading ? <CircularProgress style={{color: "#fff", width: "30px"}}/> : "Submit"}</Button>
            </Box>

        </React.Fragment>
    )
}

export default UploadFlightCSV

import { Checkbox, TextField } from '@mui/material'
import React from 'react'
import PasswordFields from './PasswordFields';


const AddEditUserForm = ({ object, setObject, userRole, setUserRole }: any) => {

    const [password, setPassword] = React.useState<string>("");
    const [tempPassword, setTempPassword] = React.useState<string>("");

    const handleChange = (input: any) => {
        let tempObj: any = object;
        tempObj[input.name] = input.value;
        setObject({...tempObj});
    }

    React.useEffect(() => {
        let tempObj = {...object};
        tempObj.role = userRole;
        setObject(tempObj);
    }, [userRole]);

    React.useEffect(() => {
        let tempObj = {...object};
        if (password === tempPassword) {
            tempObj.password = password;
        }
        setObject(tempObj);
    }, [password]);

    return (
        <form>
            <TextField
                fullWidth
                label="Full Name"
                name="name"
                value={object ? object.name : ""}
                onChange={(e) => handleChange(e.target)}
                sx={{my: 2}} />
            <TextField
                fullWidth
                label="Email"
                name="email"
                value={object ? object.email : ""}
                onChange={(e) => handleChange(e.target)}
                sx={{mt: 2}} />
                <PasswordFields
                    password={password}
                    tempPassword={tempPassword}
                    setPassword={setPassword}
                    setTempPassword={setTempPassword} />
        </form>
    )
}

export default AddEditUserForm

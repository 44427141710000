import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import AccomodationService from '../../../../services/Entities/AccomodationService';
import "./AccommodationPhotoAlbum.scss";

const AccommodationPhotoAlbum = ({
    apiPhotos,
    patchPhotos,
    setPatchPhotos,
    setApiPhotos,
    submitted,
    object }: any) => {

    const accommodationClient = new AccomodationService();
    const [loading, setLoading] = React.useState<boolean>(false);

    const removePhoto = (index: number) => {
        patchPhotos.splice(index, 1);
        setPatchPhotos([...patchPhotos]);
    }

    const deletePhoto = (photo: any, index: number) => {
        setLoading(true);
        accommodationClient
            .deleteSpecificResource(`${object.id}/images/${photo.id}`)
            .then(() => {
                apiPhotos.splice(index, 1);
                setApiPhotos([...apiPhotos]);
            })
            .finally(() => setLoading(false))
    }

    return (
        <React.Fragment>
            {(!submitted && !!patchPhotos?.length) && (
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    mt: 4
                }}>
                    {patchPhotos.map((photo: any, index: number) => (
                        <Box key={photo.file_url} className='photoCard'>
                            <Box className="deleteIcon" onClick={() => removePhoto(index)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Box>
                            <Box>
                                <img src={process.env.REACT_APP_RESOURCE_URL + photo.file_url} />
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}

            <hr />

            <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    mt: 4
                }}>
                {!!apiPhotos?.length && apiPhotos.map((photo: any, index: number) => (
                    <Box key={photo.file_url} className='photoCard'>
                        <Box className="deleteIcon" onClick={() => deletePhoto(photo, index)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Box>
                        <Box>
                            <img src={process.env.REACT_APP_RESOURCE_URL + photo.file_url} />
                        </Box>
                    </Box>
                ))}
            </Box>
        </React.Fragment>
    )
}

export default AccommodationPhotoAlbum

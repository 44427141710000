import { Box, Button, CircularProgress, TextField } from '@mui/material'
import React from 'react'
import { LoginMobileInterface } from './LoginMobile';
import logo from "../../../assets/booking_logo.png";

const LoginForm: React.FC<LoginMobileInterface> = ({ setEmail, setPassword, handleRememberMe, login, loading }: LoginMobileInterface) => {
    return (
        <form>
            <>
            <Box sx={{display: "flex", justifyContent: "center"}}>
                <img src={logo} style={{ marginTop: "10px", width: "200px" }}/>
            </Box>
                <Box sx={{mb: 3}}>
                    <TextField
                        name='email'
                        type='email'
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        label="Email"
                    />
                </Box>
                <Box sx={{mb: 3}}>
                <TextField
                        name='password'
                        type='password'
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        label="Password"
                    />
                    
                </Box>
                <Button
                sx={{
                    width: "100%",
                    mt: 2
                }} onClick={login}>{loading ? <CircularProgress style={{ color: "#fff" }} /> : "Login"}</Button>
            </>
        </form>
    )
}

export default LoginForm

import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { Ref } from 'react';
import { Airline } from '../../../interfaces/Airline';
import AccomodationService from '../../../services/Entities/AccomodationService';
import CitiesService from '../../../services/Entities/CitiesService';
import UploaderService from '../../../services/Entities/UploaderService';
import CustomButton from '../../CustomButton/CustomButton';
import CustomMap from '../../CustomMap/CustomMap';
import AccommodationPhotoAlbum from './AccommodationPhotoAlbum/AccommodationPhotoAlbum';
import AccomodationDetailsLoading from './AccomodationDetailsLoading';
import "./AddEditAccomodationForm.scss";
import FacilitiesCards from './FacilitiesCards';
import FacilitiesLoading from './FacilitiesLoading';

const AddEditAccomodationForm = ({
    object,
    setApiPhotos,
    setObject,
    setPatchObject,
    patchObject,
    apiPhotos,
    patchPhotos,
    setPatchPhotos,
    handleInputChange,
    loading,
    setPhoneNumberValid,
    setSubmitted,
    submitted,
    formState
}: any) => {

    const [citiesLoading, setCitiesLoading] = React.useState<boolean>(false);
    const [TypesLoading, setTypesLoading] = React.useState<boolean>(false);
    const [types, setTypes] = React.useState<any>([])
    const [facilities, setFacilities] = React.useState<any[]>([]);
    const [photosLoading, setPhotosLoading] = React.useState<boolean>(false);
    const [fileUrlArray, setFileUrlArray] = React.useState<any[]>([]);
    const citiesClient = new CitiesService();
    const accommodationClient = new AccomodationService();
    const [cities, setCities] = React.useState<any>([]);
    const uploader = React.useRef<any>();
    const uploaderClient = new UploaderService();
    const phoneNumInput = React.useRef<any>();

    const handleFileUpload = (e: any) => {
        let arr = Array.from(e.target.files);
        uploadImages(arr);
    }

    React.useEffect(() => {
        if (submitted) {
            setFileUrlArray([]);
        }
    }, [submitted]);

    const validateForm = (phone: any): void => {
        const pattern = /09\d{8}/;
        return setPhoneNumberValid(pattern.test(phone.current.value));
    }

    const uploadImages = (files: any[]) => {
        let formData = new FormData;
        files.forEach((file) => formData.append("image", file as Blob));
        setPhotosLoading(true);
        uploaderClient
            .postSpecificResource("images", formData)
            .then((res) => {
                let tempfileUrlArray = [...fileUrlArray];
                res.data.data.forEach((photo: any) => tempfileUrlArray.push({ file_url: photo.file_url }));
                setPatchPhotos(tempfileUrlArray);
                handleInputChange(tempfileUrlArray);
                setFileUrlArray(tempfileUrlArray);
                setSubmitted(false);
            })
            .finally(() => setPhotosLoading(false))
    }

    const getCities = () => {
        setCitiesLoading(true)
        citiesClient
            .getAllResources({})
            .then((res) => {
                setCities(res.data.data);
            })
            .finally(() => setCitiesLoading(false));
    }

    const getTypes = () => {
        setTypesLoading(true)
        accommodationClient
            .getSpecificResource("types", {})
            .then((res) => {
                setTypes(res.data.data);
            })
            .finally(() => setTypesLoading(false));
    }

    React.useEffect(() => {
        getCities();
        getTypes();
    }, []);

    return (
        <>
            <form>
              <Typography variant="h3" sx={{mb: 4, color: "#333"}}>Details</Typography>
                {loading ? <AccomodationDetailsLoading /> : (
                    <Grid container spacing={2}>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <TextField fullWidth
                                label="Name"
                                name="name"
                                value={object ? object.name : ""}
                                onChange={(e) => handleInputChange(e)}
                                sx={{ my: 1 }} />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <TextField fullWidth
                                label="Phone Number"
                                name="phone"
                                type="tel"
                                value={object ? object.phone : ""}
                                inputProps={{
                                    pattern: "/09\d{8}/"
                                }}
                                onChange={(e) => { handleInputChange(e); validateForm(phoneNumInput) }}
                                inputRef={phoneNumInput}
                                sx={{ my: 1 }} />
                        </Grid>

                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <TextField fullWidth
                                name="area_name"
                                label="Area"
                                value={object ? object.area_name : ""}
                                onChange={(e) => handleInputChange(e)}
                                sx={{ my: 1 }} />
                        </Grid>

                        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                            <TextField fullWidth
                                name="rating"
                                type="number"
                                label="Rating"
                                value={object ? object.rating : 0}
                                onChange={(e) => handleInputChange(e)}
                                sx={{ my: 1 }} />
                        </Grid>

                        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                            <TextField fullWidth
                                label="Min Price"
                                name="price_from"
                                type="number"
                                value={object ? object.price_from : ""}
                                onChange={(e) => handleInputChange(e)}
                                sx={{ my: 1 }} />
                        </Grid>

                        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                            <TextField fullWidth
                                label="Max Price"
                                name="price_to"
                                type="number"
                                value={object ? object.price_to : ""}
                                onChange={(e) => handleInputChange(e)}
                                sx={{ my: 1 }} />
                        </Grid>

                        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                            <FormControl fullWidth sx={{ my: 1 }}>
                                {(TypesLoading) ? <CircularProgress /> : (
                                    <>
                                        <InputLabel id="type_label">Type Of Accommodation</InputLabel>
                                        <Select
                                            name="type"
                                            defaultValue={object?.type ? object.type : ""}
                                            onChange={(e) => handleInputChange(e)}>
                                            {
                                                types.map((type: string, index: number) => (
                                                    <MenuItem key={index} value={type}>{type}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                            <FormControl fullWidth sx={{ my: 1 }}>
                                {(citiesLoading) ? <CircularProgress /> : (
                                    <>
                                        <InputLabel id="city_label">City</InputLabel>
                                        <Select
                                            labelId="city_label"
                                            name="city_id"
                                            value={object?.city_id ? object.city_id : ""}
                                            onChange={(e) => handleInputChange(e)}>
                                            {
                                                cities.map((city: Airline, index: number) => (
                                                    <MenuItem key={index} value={city.id}>{city.name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField fullWidth
                                label="Description"
                                name="description"
                                type="text"
                                value={object?.description ? object.description : ""}
                                onChange={(e) => handleInputChange(e)}
                                sx={{ my: 1 }} />
                        </Grid>

                    </Grid>
                )}

                <hr />

                <Typography variant="h3">Facilities</Typography>

                {loading ? <FacilitiesLoading /> : (
                    <FacilitiesCards object={object}
                        setObject={setObject}
                        setPatchObject={setPatchObject}
                        patchObject={patchObject}
                        formState={formState} />
                )}

                <hr />

                <Box
                    style={{ display: "flex", clear: "both" }}>
                    <Typography variant="h3" sx={{ alignSelf: "center", marginRight: "10px" }}>Photos</Typography>
                    <CustomButton
                        className="iconButton"
                        onClick={() => uploader.current.click()}>
                        <FontAwesomeIcon className="icon"
                            icon={faPaperclip} />
                    </CustomButton>
                </Box>
                <input
                    name="flyer"
                    type="file"
                    ref={uploader}
                    multiple
                    hidden
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e)} />
                <AccommodationPhotoAlbum patchPhotos={patchPhotos}
                    apiPhotos={apiPhotos}
                    setPatchPhotos={setPatchPhotos}
                    setApiPhotos={setApiPhotos}
                    object={object}
                    submitted={submitted} />
            </form>
            <hr />
            <Typography variant="h3">Location</Typography>
            <Typography variant='body2'>Enter the location coordinates or mark it on the map with the marker.</Typography>

            <CustomMap object={object} setObject={setObject}
                patchObject={patchObject} setPatchObject={setPatchObject}
                loading={loading} />
        </>
    )

}

export default AddEditAccomodationForm;
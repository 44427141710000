import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { Facility } from '../../../interfaces/Facility';
import FacilitiesService from '../../../services/Entities/FacilitiesService';
import "./AddEditAccomodationForm.scss";
import FacilitiesLoading from './FacilitiesLoading';

const FacilitiesCards = ({ 
    object, 
    setObject, 
    setPatchObject,
    patchObject,
    formState
     }: any) => {

    const [facilitiesLoading, setFacilitiesLoading] = React.useState<boolean>(false);
    const [facilities, setFacilities] = React.useState<any[]>([]);
    const facilitiesClient = new FacilitiesService();

    const getFacilities = () => {
        setFacilitiesLoading(true)
        facilitiesClient
            .getAllResources({})
            .then((res: { data: { data: any; }; }) => setFacilities(res.data.data))
            .finally(() => setFacilitiesLoading(false));
    }

    const setObjectFacilities = (facility: Facility) => {
        let tempFacilities = object?.facilities ? [...object.facilities] : [];
        const match: number = tempFacilities.findIndex((x:any) => x.id == facility.id);
        (match >= 0) ? tempFacilities.splice(match, 1) : tempFacilities.push({id: facility.id});
        var tempFacilitiesIds: { id: string | undefined; }[] = [];
        tempFacilities.forEach((fac: Facility) => tempFacilitiesIds.push({id: fac.id}));

        // if (tempFacilities.some((fac: Facility) => fac.id === facility.id))
        //     tempFacilities.splice(index, 1)
        // else 
        //     tempFacilities.push(facility);

        setObject({...object, facilities: tempFacilities});
        formState === "create" ? setPatchObject({...patchObject, facilities: {connect: tempFacilitiesIds}}) : setPatchObject({...patchObject, facilities: {set: tempFacilitiesIds}});
    }

    React.useEffect(() => getFacilities(), []);
    
    

    return (
        <>
            <Box sx={{display: "flex", mt: 3}}>
                {facilitiesLoading ? (<FacilitiesLoading />) : (
                    !!facilities.length && facilities.map((facility: any, index: number) => (
                        <>
                            <Box sx={{display: "flex", flexDirection: "column"}} key={facility.id} onClick={() => setObjectFacilities(facility)}>
                                <img src={process.env.REACT_APP_RESOURCE_URL + facility.icon_url}
                                    className={object?.facilities?.some((fac: Facility) => fac.id === facility.id) ? "selectedFacilityCard": "facilityCard"} />
                                <Typography align="center" sx={{mt: 3}}>{facility.name}</Typography>
                            </Box>
                        </>
                    ))
                )}
            </Box>
        </>
    )
}

export default FacilitiesCards

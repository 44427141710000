import { faAd, faHome, faPlane, faUsers } from '@fortawesome/free-solid-svg-icons'
import { CircularProgress, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { AxiosResponse } from 'axios'
import React from 'react'
import DashboardCountCard from '../../components/Dashboard/DashboardCountCard/DashboardCountCard'
import { CountCard } from '../../interfaces/CountCard'
import AccomodationService from '../../services/Entities/AccomodationService'

const Dashboard = () => {
  let countCards: CountCard[] = [
    {label: "Accommodation", count: 0, icon: faHome},
    {label: "Flights", count: 0, icon: faPlane},
    {label: "Ads", count: 0, icon: faAd},
    {label: "Users", count: 0, icon: faUsers}
  ];
  const [accoLoading, setAccoLoading] = React.useState<boolean>(false);
  const [flightsLoading, setFlightsLoading] = React.useState<boolean>(false);
  const [adsLoading, setAdsLoading] = React.useState<boolean>(false);
  const [usersLoading, setUsersLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const accommodationClient = new AccomodationService();

  React.useEffect(() => {
    getAccommodationCount();
    getFlightsCount();
    getUsersCount();
    getAdsCount();
  }, []);

  const getFlightsCount = () => {
    setFlightsLoading(true);
    accommodationClient.getAllResources()
    .then((res: AxiosResponse) => {
      countCards[1].count = res.data.data.total;
    })
    .finally(() => setFlightsLoading(false))
  }

  const getUsersCount = () => {
    setUsersLoading(true);
    accommodationClient.getAllResources()
    .then((res: AxiosResponse) => {
      countCards[3].count = res.data.data.total;
    })
    .finally(() => setUsersLoading(false))
  }

  const getAdsCount = () => {
    setAdsLoading(true);
    accommodationClient.getAllResources()
    .then((res: AxiosResponse) => {
      countCards[2].count = res.data.data.total;
    })
    .finally(() => setAdsLoading(false))
  }
  
  const getAccommodationCount = () => {
    setAccoLoading(true);
    accommodationClient.getAllResources()
    .then((res: AxiosResponse) => {
      countCards[0].count = res.data.data.total;
    })
    .finally(() => setAccoLoading(false))
  }

  

  return (
    <Box sx={{p: 3}}>
      <Grid container spacing={4}>
        {loading ? <CircularProgress/> : countCards.map((card: CountCard, index: number) => (
          <Grid key={index} item xl={3} lg={3} md={3} sm={3} xs={12}>
            <DashboardCountCard label={card.label} count={card.count} icon={card.icon}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Dashboard
import { faAngleLeft, faAngleRight, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from "../../assets/booking_logo.png";
import { routes } from '../../configs/menuRoutes';
import useScreenSize from '../../hooks/useScreenSize';
import { logout } from '../../store/actions/session';
import GenericModal from '../Modal/Modal';
import Enable2FA from '../PageComponents/Authentication/Enable2FA';
import "./Sidebar.scss";
const Sidebar = ({ isSidebarCollapsed }: any) => {

    const dispatch = useDispatch();
    const [activeRoute, setActiveRoute] = React.useState<string>(window.location.pathname.split("/")[1]);
    const [collapsed, setCollapsed] = React.useState<boolean>(true);
    const [modal2fa, setModal2fa] = React.useState<boolean>(false);

    const device = useScreenSize();
    const history = useHistory();

    const logUserOut = (): void => {
        dispatch(logout());
        history.push("/login");
    }

    const close2faModal = (): void => {
        setModal2fa(false)
    }


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
            className={"sidebar " + (collapsed ? "" : "full")}
        >

            <GenericModal
                size="small"
                state={modal2fa}
                onClose={close2faModal}>
                <Enable2FA />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 3
                    }}>
                    <Button color='secondary'
                        onClick={close2faModal}>Close</Button>
                </Box>
            </GenericModal>


            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
                className="sidebarAction"
            >
                <FontAwesomeIcon size="3x"
                    icon={collapsed ? faAngleRight : faAngleLeft}
                    style={{ margin: "15px", cursor: "pointer" }}
                    className="icon"
                    onClick={() => {
                        setCollapsed(!collapsed);
                        isSidebarCollapsed(!collapsed);
                    }} />
            </Box>
            <Box className="topBox">
                {(device !== "tablet" && !collapsed) && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        className="laptopBox"
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                            <img src={logo} alt="user photo" style={{ marginTop: "10px", width: "100px" }} />
                        </Box>
                    </Box>
                )}
            </Box>

            <Box
                className="linksBox"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start"
                }}
            >
                {routes.map((route, index) => (
                    <React.Fragment key={index}>
                        <Link
                            to={route.path}
                            className={"sideLink " + (activeRoute === route.routeActiveHandler ? "active" : "")}
                            onClick={() => setActiveRoute(route.routeActiveHandler)}
                        >
                            {route.icon ? <FontAwesomeIcon icon={route.icon} /> : route.imgSrc}
                            <span style={{ marginLeft: "35px" }}>{route.label}</span>
                        </Link>
                    </React.Fragment>
                )
                )}

                <div onClick={logUserOut}>
                    <a className="sideLink">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        <span style={{ marginLeft: "35px" }}>Log out</span>
                    </a>
                </div>

            </Box>
        </Box>
    )
}

export default Sidebar

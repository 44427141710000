import { Box, Card, Typography, CircularProgress} from "@mui/material";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomButton from "../../../components/CustomButton/CustomButton";
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import AddEditFlightForm from '../../../components/PageComponents/Flights/AddEditFlightForm';
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import FlightService from "../../../services/Entities/FlightService";
import { showErrorAlert, showSuccessAlert } from '../../../store/actions/alerts';
import "./AddEditFlight.scss";

const AddEditFlight = () => {
    const [formState, setFormState] = React.useState<string>("");
    const [object, setObject] = React.useState<any>(undefined);
    const [patchObject, setPatchObject] = React.useState<any>(null);
    const [from, setFrom] = React.useState<any>();
    const [to, setTo] = React.useState<any>();
    const [loading, setLoading] = React.useState(false);
    const flightsClient = new FlightService();
    const session = useStoreSessionSelector();
    const { id }: any = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const deleteResourceHandler = () => {
        if (!id) {
            dispatch(showErrorAlert({ message: "There is no flight to delete! You are in create-mode." }));
        }
        else {
            const payload = {
                id: id,
                token: session.token
            };
            flightsClient
                .deleteResource(payload)
                .then(() => history.push("/flights"))
                .finally(() => setLoading(false));
        }
    }

    const fetchSpecificResource = () => {
        setLoading(true);
        const payload = {
            token: session.token,
        };
        flightsClient
            .getSpecificResource(id, payload)
            .then((res) => {
                setObject({ ...res["data"]["data"], takeoff_time: res["data"]["data"].takeoff_time?.slice(0, 16), arrival_time: res["data"]["data"].arrival_time?.slice(0, 16), boarding_time: res["data"]["data"].boarding_time?.slice(0, 16), });
                setTo(res["data"]["data"]["to_id"]);
                setFrom(res["data"]["data"]["from_id"]);
                setFormState("edit");
            })
            .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        if (id) {
            setFormState("edit");
            fetchSpecificResource();
        }
        else
            setFormState("create");
    }, [id]);

    const handleInputChange = (e: any) => {
        if (e?.target?.name) {
            setObject({ ...object, [e.target.name]: e?.target?.value });
            switch (e.target.name) {
                case "airline":
                    setPatchObject({ ...patchObject, airline: { connect: { id: e.target.value } } });
                    break;
                case "from":
                    setPatchObject({ ...patchObject, [e.target.name]: { connect: { id: e.target.value } } });
                    break;
                case "to":
                    setPatchObject({ ...patchObject, [e.target.name]: { connect: { id: e.target.value } } });
                    break;
                case "boarding_time":
                    setPatchObject({ ...patchObject, boarding_time: e.target.value + ":00.000Z" });
                    break;
                case "takeoff_time":
                    setPatchObject({ ...patchObject, takeoff_time: e.target.value + ":00.000Z" });
                    break;
                case "arrival_time":
                    setPatchObject({ ...patchObject, arrival_time: e.target.value + ":00.000Z" });
                    break;
                default:
                    setPatchObject({ ...patchObject, [e.target.name]: e.target.value });
                    break;
            }
        }
    }

    const submitResource = () => {
        setLoading(true);
        if (formState === "create") {
            const payload = {
                ...patchObject,
                status: patchObject?.status ?? "BOARDING"
            };
            flightsClient
                .postResource(payload)
                .then((res) => {
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    history.push("/flights/" + res["data"]["data"]["id"]);
                    setPatchObject({});
                })
                .finally(() => setLoading(false));
        } else {
            flightsClient
                .patchResource(id, { ...patchObject })
                .then((res) => {
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    setObject(res["data"]["data"]);
                    fetchSpecificResource();
                    setPatchObject({});
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <>
            <GenericHeader deleteResourceHandler={deleteResourceHandler}
                model="flight" />
            <Box sx={{ p: 3 }}>
              <Typography variant="h1" sx={{mb: 4, color: "#333"}}>{formState === "create" ? "Add" : "Edit"} Flight</Typography>
                <Card sx={{ p: 3 }}>
                    <React.Fragment>
                        <AddEditFlightForm
                            object={object}
                            setObject={setObject}
                            handleInputChange={handleInputChange} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%"
                            }}>
                            <CustomButton
                                style={{ width: "25%", marginTop: "20px" }}
                                onClick={submitResource}
                                disabled={!object?.code}>
                                {loading ? <CircularProgress style={{ color: "#fff" }} /> : "SAVE"}
                            </CustomButton>
                        </Box>
                    </React.Fragment>
                </Card>

            </Box>
        </>
    )
}

export default AddEditFlight

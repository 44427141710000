import { faFile, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, FormControl, Grid, TextField, CircularProgress } from '@mui/material'
import { Skeleton } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { Facility } from '../../../interfaces/Facility'
import UploaderService from '../../../services/Entities/UploaderService'
import CustomButton from '../../CustomButton/CustomButton'

interface facilityFormProps {
    facility: Facility,
    setFacility: Dispatch<SetStateAction<Facility>>,
    loading: boolean
}

const FacilitiesForm = (facilityProp: facilityFormProps) => {
    const [FileLoading, setFileLoading] = React.useState<boolean>(false);
    const [file, setfile] = React.useState<string>("");
    const uploader = new UploaderService();
    const imageUploader = React.useRef<any>();

    const imageUpload = (e: any) => {
        setFileLoading(true);
        console.log(e.target.files[0])
        setfile(e.target.files[0].name)
        let formData = new FormData();
        formData.append("image", e.target.files[0] as Blob);
        uploader.postSpecificResource("image", formData)
            .then((res) => facilityProp.setFacility({ ...facilityProp.facility, icon_url: res.data.data.file_url }))
            .finally(() => setFileLoading(false))
    }


    return (
        <Box>
            {facilityProp.loading ? (
                <Box sx={{display: "flex", justifyContent: "center"}}><CircularProgress/></Box>
            ) : (
                <Grid container spacing={2}>
                    {facilityProp.facility.icon_url  && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{display: "flex", justifyContent: "center"}}>
                                <img src={process.env.REACT_APP_RESOURCE_URL + facilityProp.facility.icon_url} style={{maxWidth: "200px"}}/>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextField
                            label="Name"
                            fullWidth
                            type='text'
                            name='name'
                            value={facilityProp.facility.name ? facilityProp.facility.name : ""}
                            onChange={(e) => facilityProp.setFacility({ ...facilityProp.facility, name: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        {FileLoading ? <Skeleton variant='text' width={200} /> :
                            <TextField
                                fullWidth
                                hidden
                                inputRef={imageUploader}
                                type='file'
                                name='image'
                                onChange={(e) => imageUpload(e)} />}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                clear: "both",
                                marginTop: "10px"
                            }}>
                            <Box sx={{alignSelf: "center"}}>
                                Selected File: {file ? file : "none"}
                            </Box>
                            <CustomButton
                                className="iconButton"
                                type="button"
                                onClick={() => imageUploader.current.click()}>
                                <FontAwesomeIcon icon={faPaperclip} className='icon'
                                    style={{ float: "left" }} />
                            </CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            )}

        </Box>
    )
}

export default FacilitiesForm

import { Grid } from '@mui/material'
import { Skeleton } from '@mui/material'
import React from 'react'

function AccomodationDetailsLoading() {
    return (
        <Grid container spacing={2}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((loadingUnit) => (
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} key={loadingUnit}>
                    <Skeleton variant="text" height={20} width={100}/>
                    <Skeleton variant="text" width="100%" />
                </Grid>
                ))}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Skeleton variant="text" height={20} width={100}/>
                    <Skeleton variant="text" width="100%" />
                </Grid>
            </Grid>
    )
}

export default AccomodationDetailsLoading

import { Box, Card, Typography, CircularProgress, Skeleton  } from "@mui/material";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomButton from "../../../components/CustomButton/CustomButton";
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import AddEditAccomodationForm from "../../../components/PageComponents/Accomodation/AddEditAccomodationForm";
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import AccomodationService from "../../../services/Entities/AccomodationService";
import { showErrorAlert, showSuccessAlert } from '../../../store/actions/alerts';
import "./AddEditAccomodation.scss";



const AddEditAccomodation = () => {
    const [formState, setFormState] = React.useState<string>("");
    const [object, setObject] = React.useState<any>(undefined);
    const [patchObject, setPatchObject] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [apiPhotos, setApiPhotos] = React.useState<any[]>([]);
    const [patchPhotos, setPatchPhotos] = React.useState<any[]>([]);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const accomodationService = new AccomodationService();
    const [phoneNumberValid, setPhoneNumberValid] = React.useState<boolean>(true);
    const session = useStoreSessionSelector();
    const { id }: any = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const deleteResourceHandler = () => {
        if (!id) {
            dispatch(showErrorAlert({ message: "There is no accommodation to delete! You are in create-mode." }));
        }
        else {
            accomodationService
                .deleteResource(id)
                .then(() => history.push("/accomodations"))
                .finally(() => setLoading(false));
        }
    }

    const fetchSpecificResource = () => {
        setLoading(true);
        accomodationService
            .getSpecificResource(id)
            .then((res) => {
                setObject(res.data.data);
                setApiPhotos(res.data.data?.photos);
            })
            .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        if (id) {
            setFormState("edit");
            fetchSpecificResource();
        }
        else
            setFormState("create");
    }, [id]);


    const submitResource = () => {
        if (phoneNumberValid) {
            setLoading(true);
            if (formState === "create") {
                accomodationService
                    .postResource({...patchObject})
                    .then((res) => {
                        dispatch(showSuccessAlert("Submitted Successfully!"));
                        history.push("/accomodations/" + res["data"]["data"]["id"]);
                        setSubmitted(true);
                        setPatchObject({});
                    })
                    .finally(() => setLoading(false));
            } else {
                accomodationService
                    .patchResource(id, {...patchObject})
                    .then((res) => {
                        dispatch(showSuccessAlert("Submitted Successfully!"));
                        setObject(res["data"]["data"]);
                        fetchSpecificResource();
                        setSubmitted(true);
                        setPatchObject({});
                    })
                    .finally(() => setLoading(false));
            }
        }
        else{
            dispatch(showErrorAlert({message: "Make sure the number starts with '09' and is 10 digits."}))
        }
    }

    const handleInputChange = (e: any) => {
        if (e?.target?.name) {
            setObject({...object, [e.target.name]: e?.target?.value});
            switch (e.target.name) {
                case "city_id":
                    setPatchObject({...patchObject, city: {connect: {id: e.target.value}}});
                    break;
                case "price_from":
                    setPatchObject({...patchObject, [e.target.name]: (e.target.value * 1)});
                    break;
                case "price_to":
                    setPatchObject({...patchObject, [e.target.name]: (e.target.value * 1)});
                    break;
                case "rating":
                    setPatchObject({...patchObject, [e.target.name]: (e.target.value * 1)});
                    break;
                default:
                    setPatchObject({...patchObject, [e.target.name]: e.target.value});
                    break;
            }
        }
    }

    React.useEffect(() => {
        setPatchObject({...patchObject, photos: {create: patchPhotos}});
    }, [patchPhotos])

    return (
        <>
            <GenericHeader deleteResourceHandler={deleteResourceHandler}
                model="accomodation" />
            <Box sx={{p: 3}}>
              <Typography variant="h1" sx={{mb: 4, color: "#333"}}>{formState === "create" ? "Add" : "Edit"} Accomodation</Typography>
                <Card sx={{p: 3}}>
                            <AddEditAccomodationForm
                                object={object}
                                setObject={setObject}
                                setPatchPhotos={setPatchPhotos}
                                setApiPhotos={setApiPhotos}
                                handleInputChange={handleInputChange}
                                loading={loading}
                                apiPhotos={apiPhotos}
                                setPatchObject={setPatchObject}
                                setPhoneNumberValid={setPhoneNumberValid}
                                patchObject={patchObject}
                                formState={formState}
                                patchPhotos={patchPhotos}
                                submitted={submitted}
                                setSubmitted={setSubmitted}/>
                            <Box 
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%"
                            }}>
                                <CustomButton
                                    style={{width: "25%", marginTop: "20px"}}
                                    onClick={submitResource}
                                    disabled={!object?.name || !object?.city_id || !object?.type}>
                                    {loading ? <CircularProgress style={{ color: "#fff" }} /> : "SAVE"}
                                </CustomButton>
                            </Box>
                </Card>

            </Box>
        </>
    )
}

export default AddEditAccomodation

import { Box, Card, CircularProgress } from "@mui/material";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomButton from "../../../components/CustomButton/CustomButton";
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import AddEditUserForm from '../../../components/PageComponents/Users/AddEditUserForm';
import useScreenSize from '../../../hooks/useScreenSize';
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import UserService from '../../../services/Entities/UserService';
import { showErrorAlert, showSuccessAlert } from '../../../store/actions/alerts';
import "./AddEditUser.scss";
// import showErrorAlert from '../../../components/Alerts/ErrorAlert/ErrorAlert';

const AddEditUser = () => {
    const [formState, setFormState] = React.useState("create");
    const [object, setObject] = React.useState({ name: "", role: "", email: "", password: "" });
    const [userRole, setUserRole] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const usersClient = new UserService();
    const session = useStoreSessionSelector();
    const {id}: any = useParams();
    const history = useHistory();
    const device = useScreenSize();
    const dispatch = useDispatch();

    const deleteResourceHandler = () => {
        if (!id) {
            dispatch(showErrorAlert({message: "There is no user to delete! You are in create-mode."}));
        }
        else {
            const payload = {
                id: id,
                token: session.token
            };
            usersClient
                .deleteResource(payload)
                .then((res) => {
                    history.push("/users");
                })
                .finally(() => setLoading(false));
        }
    }

    const fetchSpecificResource = () => {
        setLoading(true);
        const payload = {
            token: session.token,
        };
        usersClient
            .getSpecificResource(id, payload)
            .then((res) => {
                setObject(res["data"]["data"]);
                setUserRole(res["data"]["data"].role);
                setFormState("edit");
            })
            .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        if(id)
            fetchSpecificResource()
    }, [id]);


    const submitResource = () => {
        setLoading(true);
        if (formState === "create") {
            const payload = {
                ...object,
                role: userRole,
                token: session.token,
            };
            usersClient
                .postResource(payload)
                .then((res) => {
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    history.push("/users/" + res["data"]["data"]["_id"])
                })
                .finally(() => setLoading(false));
        } else {
            const payload = {
                ...object,
                role: userRole,
                token: session.token,
            };
            usersClient
                .patchResource(id, payload)
                .then((res) => {
                    console.log("res", res);
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    setObject(res["data"]["data"][0]);
                    fetchSpecificResource();
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <>
            <GenericHeader  deleteResourceHandler={deleteResourceHandler}
                            model="user" />
            <Box sx={{height: "80vh"}} >
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }}>
                    <Card sx={ device === "mobile" ? 
                         {width: "100%", p: 4} : {width: "50%", p: 4}}>
                        <h3 style={{ color: "#cb3440", fontSize: "20px" }}>{formState === "create" ? "Add" : "Edit"} User</h3>
                        <AddEditUserForm
                            object={object}
                            setObject={setObject}
                            userRole={userRole}
                            setUserRole={setUserRole}/>
                        <CustomButton
                                style={{width: "100%", marginTop: "15px"}}
                                onClick={submitResource}
                                disabled={!object?.name || !object?.email || !object?.password || !object?.role}>
                            {loading ? <CircularProgress style={{color: "#fff"}}/> : "SAVE"}
                        </CustomButton>
                    </Card>
                </Box>
            </Box>
        </>
    )
}

export default AddEditUser

import { Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import React from 'react'

const FacilitiesLoading = () => {
    return (
        <Box sx={{display: "flex"}}>
            {[1, 2, 3, 4, 5, 6].map(() => (
                <Box sx={{flexDirection: "column", alignItems: "center"}}>
                    <Skeleton variant="circular" width={100} height={100} sx={{mx: 3}} />
                    <Skeleton variant="text" width={120} />
                </Box>
            ))}
        </Box>
    )
}

export default FacilitiesLoading

import React from 'react';
import LoginForm from './LoginForm';
import logo from '../../../assets/booking_logo.png';
import { Box } from '@mui/material';

interface LoginPCInterface {
    setEmail: any,
    setPassword: any,
    handleRememberMe: any,
    login: any,
    loading: boolean
}

const LoginPC: React.FC<LoginPCInterface> = ({ setEmail, setPassword, handleRememberMe, login, loading }) => {
    return (
        <Box className="bg-custom-gradient" 
            sx={{p: 0, height: "100vh"}}>
            <Box sx={{height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      m: 0}}>
                <Box className="login-container glass-card" 
                     sx={{ padding: "100px", color: "#fff" }}>
                    <>
                        <LoginForm
                            setEmail={setEmail}
                            setPassword={setPassword}
                            login={login}
                            loading={loading}
                            handleRememberMe={handleRememberMe} />
                    </>
                </Box>
            </Box>
        </Box>
    )
}

export default LoginPC

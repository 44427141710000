import { SidebarItem } from "../interfaces/SidebarItem";
import { faAd, faChartBar, faHome, faPlane, faUser, faWifi } from '@fortawesome/free-solid-svg-icons';

export const routes: SidebarItem[] = [
    // { path: "/dashboard", label: "Dashboard", routeActiveHandler: "dashboard", icon: faChartBar },
    { path: "/flights", label: "Flights", routeActiveHandler: "flights", icon: faPlane },
    { path: "/accomodations", label: "Accommodations", routeActiveHandler: "accomodations", icon: faHome },
    { path: "/facilities", label: "Facilities", routeActiveHandler: "facilities", icon: faWifi },
    { path: "/ads", label: "Advertising", routeActiveHandler: "ads", icon: faAd },
    { path: "/users", label: "Users", routeActiveHandler: "users", icon: faUser },
];
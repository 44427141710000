import { faCheck, faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox, CircularProgress, Paper,
  Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import NoResourceAvailable from "../NoResourceAvailable/NoResourceAvailable";
import "./GenericTable.scss";
import * as _ from "lodash";

export interface GenericTableParams {
  list: any[],
  totalCount: number,
  selectedRows: any,
  page: number,
  onSelectedChange: any,
  rowsPerPage: number,
  onRowsChange: any,
  onPageChange: any,
  model: string,
  passRow: any,
  image: string,
  tableConfig: any,
  loading: boolean,
  showCheckbox?: boolean,
  frontendPagination?: boolean
};

const GenericTable = (params: GenericTableParams) => {

  const [selected, setSelected] = useState<any>([]);
  const [active, setActive] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = (row: any) => {
    params.passRow(row);
  }

  const handleSelectionChange = (rowId: any) => {
    let data = [...params.selectedRows];
    if (data.includes(rowId)) {
      data = data.filter((item) => item !== rowId);
    } else {
      data.push(rowId);
    }
    setSelected(data);
    params.onSelectedChange(data);
  };
  const selectAll = (checked: any) => {
    let dataList = [...params.selectedRows];
    if (checked) {
      params.list.forEach((item: any) => {
        if (!dataList.includes(item.id)) {
          dataList.push(item.id);
        }
      });
      dataList = [...new Set(dataList)];
      setSelected(dataList);
    } else {
      params.list.forEach((item: any) => {
        dataList = dataList.filter((listItem) => listItem !== item.id);
      });
      setSelected(dataList);
    }
    params.onSelectedChange(dataList);
  };
  return (
    <Box
      alignItems="flex-start"
      className="usersList w-100"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {(loading || params.loading) ? (

        <Box sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          color: "#cb3440"
        }}>
          <CircularProgress style={{ color: "#cb3440" }} />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} className="tableContainer">
            <Table className="usersListTable">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {params.showCheckbox !== false && (
                      <Checkbox
                        className="checkBox"
                        color="primary"
                        onChange={(e) => selectAll(e.target.checked)}
                        checked={params.selectedRows?.length > 0 && params.selectedRows?.length === params.list?.length}
                      />
                    )}
                  </TableCell>
                  {params.tableConfig.map((row: any, index: number) => <TableCell key={index} align="center">{row.heading}</TableCell>)}
                </TableRow>
              </TableHead>
              {params.totalCount > 0 && (
                <TableBody>
                  {params.frontendPagination === true ? (
                    params.list.slice(params.page * params.rowsPerPage, params.page * params.rowsPerPage + params.rowsPerPage).map((row: any, index: number) => (
                      <TableRow key={index} onClick={() => {
                        navigate(row);
                        setActive(row);
                      }} className={active === row ? "activeRow" : ""}>
                        <TableCell align="left">
                          {params.showCheckbox !== false && (
                            <Checkbox
                              className="checkBox"
                              color="primary"
                              value={row.id ? row.id : ""}
                              checked={params.selectedRows.includes(row.id)}
                              onChange={(e) => handleSelectionChange(row.id)}
                              onClick={(e) => e.stopPropagation()}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          )}
                        </TableCell>
                        {params.tableConfig.map((object: any, index: number) => (
                          <TableCell key={index} className="pointer" align="center">
                            {/* if column is date, display formatted date */}
                            {object.type === "date" && (
                              dayjs( _.get(row, object.attribute)).format('MM/DD/YYYY') ? dayjs( _.get(row, object.attribute)).format('MM/DD/YYYY H:m:s') : "none"
                            )}
                            {/*  */}
                            {object.type === "boolean" && (
                               _.get(row, object.attribute) == true ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />
                            )}
                            {(object.type === "string" || object.type === "number") && (
                               _.get(row, object.attribute) ? _.get(row, ['object', 'attribute']) : "none"
                            )}
                            {object.type == "currency" && (
                               _.get(row, object.attribute) ? ("LYD " +  _.get(row, object.attribute)) : "none"
                            )}
                            {object.type == "link" && (
                               _.get(row, object.attribute) ? <a href={process.env.REACT_APP_API_URL?.slice(0, 21) + "/" +  _.get(row, object.attribute)} target="_blank"><FontAwesomeIcon icon={faFilePdf} className="fontAwesomeIcon"/></a> : "none"
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    params.list.map((row: any, index: number) => (
                      <TableRow key={index} onClick={() => {
                        navigate(row);
                        setActive(row);
                      }} className={active === row ? "activeRow" : ""}>
                        <TableCell align="left">
                          {params.showCheckbox !== false && (
                            <Checkbox
                              className="checkBox"
                              color="primary"
                              value={row.id ? row.id : ""}
                              checked={params.selectedRows.includes(row.id)}
                              onChange={(e) => handleSelectionChange(row.id)}
                              onClick={(e) => e.stopPropagation()}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          )}
                        </TableCell>
                        {params.tableConfig.map((object: any, index: number) => (
                          <TableCell key={index} className="pointer" align="center">
                            {/* if column is date, display formatted date */}
                            {object.type === "date" && (
                              dayjs(row[object.attribute]).format('MM/DD/YYYY') ? dayjs(row[object.attribute]).format('MM/DD/YYYY') : "none"
                            )}
                            {/*  */}
                            {object.type === "boolean" && (
                               _.get(row, object.attribute) == true ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />
                            )}
                            {(object.type === "string" || object.type === "number") && (
                              _.get(row, object.attribute) ? _.get(row, object.attribute) : "none"
                            )}
                            {object.type == "currency" && (
                               _.get(row, object.attribute) ? ("€ " +  _.get(row, object.attribute)) : "none"
                            )}
                            {object.type == "link" && (
                               _.get(row, object.attribute) ? <a href={process.env.REACT_APP_API_URL?.slice(0, 21) + "/" +  _.get(row, object.attribute)} target="_blank"><FontAwesomeIcon icon={faFilePdf} className="fontAwesomeIcon"/></a> : "none"
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
            {params.totalCount === 0 && <NoResourceAvailable resource={params.model + "s"} image={params.image} addButtonLine={true} />}
          </TableContainer>
          {params.totalCount > 0 && (
            <>
              <TablePagination
                className="pagination"
                component="div"
                page={params.page}
                rowsPerPage={params.rowsPerPage}
                count={params.totalCount}
                onPageChange={params.onPageChange}
                onRowsPerPageChange={params.onRowsChange}
              />
            </>
          )}
        </>
      )}
    </Box>
  )
}
export default GenericTable;
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import placeholderPic from "../../../assets/404.gif";
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import GenericTable from '../../../components/GenericTable/GenericTable';
import users from '../../../configs/users';
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import UserService from '../../../services/Entities/UserService';

const UsersTableView = () => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const session = useStoreSessionSelector();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRows, setSelectedRows] = useState([]);
    const history = useHistory();
    const usersClient = new UserService();

      const handleSearch = (value: string) => {
        if (value && value !== "") {
          setLoading(true);
          const payload = {
            query : {
              query_all: value
            }
          };
            usersClient
              .getAllResources(payload)
              .then((response) => {
                setList(response.data.data);
              })
              .finally(() => {
                setLoading(false);
              });
          } 
        else {
          fetchData();
        }
    };

    const fetchData = () => {
      setLoading(true);
      const payload = {
        token: session.token,
        query: {
          page: page,
          pageSize: rowsPerPage
        }
      };
      usersClient
        .getAllResources(payload)
        .then((response) => {
          setList(response.data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const deleteUser = () => {
      setLoading(true);
      const payload = {
        token: session.token,
        data: selectedRows
      };
      usersClient
        .deleteManyResource(payload)
        .then(() => {
          fetchData();
        })
        .finally(() => {
          setLoading(false);
        });
    }
     
    React.useEffect(fetchData, [page, rowsPerPage]);

    const navigateToAddPage = () => {
      history.push(`users/add`);
    }
   
    const rowTriggerAction = (row: any) => {
      history.push(`users/${row?.id}`);
    }

    const handlePageChange = (e: any, value: any) => {
      setPage(value);
    };
  
    const handleRowsChange = (e: { target: { value: string; }; }) => {
      setRowsPerPage(parseInt(e.target.value));
      setPage(0);
    };

    
  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };
    
    return (
        <Box sx={{p: 0}}>
          <GenericHeader 
            onSearch={handleSearch} 
            addResourceHandler={navigateToAddPage}
            deleteResourceHandler={deleteUser}
            model="user"/>
            <Box sx={{p: 4}}>
              <Typography variant="h1" sx={{mb: 4, color: "#333"}}>Users</Typography>
              <GenericTable
                  list={list}
                  totalCount={list.length}
                  onSelectedChange={handleRowSelectionChange}
                  selectedRows={selectedRows}
                  page={page}
                  // @ts-ignore
                  loading={loading}
                  rowsPerPage={rowsPerPage}
                  onRowsChange={handleRowsChange}
                  onPageChange={handlePageChange}
                  image={placeholderPic}
                  tableConfig={users}
                  model="user"
                  passRow={rowTriggerAction}
                />
            </Box>
        </Box>
    )
}

export default UsersTableView

import React from "react";
import "./Modal.scss";
import { Box, Dialog } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const GenericModal = (props: any) => {
  const {
    state,
    onClose,
    persist = false,
    children,
    size,
    showCloseIcon = true,
  } = props;
  const fullScreen = size === "fullscreen";
  return (
    <Dialog
      classes={{
        paper: "modal " + (size ? size : " ")
    }}
      disableEscapeKeyDown={persist}
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : "lg"}
      onClose={onClose}
      open={state}
    >
    <Box sx={{display: "flex", 
                 justifyContent: "flex-end", 
                 cursor: "pointer",
                 margin: "10px"}}>
      {showCloseIcon && <FontAwesomeIcon icon={faTimes} className="closeIcon" onClick={onClose} />}
    </Box>
    <Box sx={{padding: "20px"}}>
      {children}
    </Box>
    </Dialog>
  );
};

export default GenericModal;

import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import placeholderPic from "../../../assets/404.gif";
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import GenericTable from '../../../components/GenericTable/GenericTable';
import accomodations from "../../../configs/accomodations";
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import AccomodationService from '../../../services/Entities/AccomodationService';

const AccomodationTableView = () => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const session = useStoreSessionSelector();
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRows, setSelectedRows] = useState([]);
    const history = useHistory();
    const accomodationService = new AccomodationService();

      const handleSearch = (value: string) => {
        if (value && value !== "") {
          setLoading(true);
          const payload = {
            query: {
              'filters[name]': value, 
              page: page,
              pageSize: rowsPerPage,
              noAds: true
            }
          };
            accomodationService
              .getAllResources(payload)
              .then((response) => {
                setList(response.data.data);
                setTotal(response.data.total);
              })
              .finally(() => {
                setLoading(false);
              });
          } 
        else {
          fetchData();
        }
    };

    const fetchData = () => {
      setLoading(true);
      const payload = {
        token: session.token,
        query: {
          page: page,
          pageSize: rowsPerPage,
          noAds: true
        }
      };
      accomodationService
        .getAllResources(payload)
        .then((response) => {
          setList(response.data.data);
          setTotal(response.data.total);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const deleteFlight = () => {
      setLoading(true);
      const payload = {
        data: selectedRows
      };
      accomodationService
        .deleteManyResource(payload)
        .then(() => {
          fetchData();
        })
        .finally(() => {
          setLoading(false);
        });
    }
     
    React.useEffect(fetchData, [page, rowsPerPage]);

    const navigateToAddPage = () => {
      history.push(`accomodations/add`);
    }
   
    const rowTriggerAction = (row: any) => {
      history.push(`accomodations/${row?.id}`);
    }

    const handlePageChange = (e: any, value: any) => {
      setPage(value);
    };
  
    const handleRowsChange = (e: { target: { value: string; }; }) => {
      setRowsPerPage(parseInt(e.target.value));
      setPage(0);
    };

    
  const handleRowSelectionChange = (data: any) => {
    setSelectedRows(data);
  };
    
    return (
        <Box sx={{p: 0}}>
          <GenericHeader 
            onSearch={handleSearch} 
            addResourceHandler={navigateToAddPage}
            deleteResourceHandler={deleteFlight}
            model="accommodation"/>
            <Box sx={{p: 4}}>
              <Typography variant="h1" sx={{mb: 4, color: "#333"}}>Accommodations</Typography>
              <GenericTable
                  list={list}
                  totalCount={total}
                  onSelectedChange={handleRowSelectionChange}
                  selectedRows={selectedRows}
                  page={page}
                  // @ts-ignore
                  loading={loading}
                  rowsPerPage={rowsPerPage}
                  onRowsChange={handleRowsChange}
                  onPageChange={handlePageChange}
                  image={placeholderPic}
                  tableConfig={accomodations}
                  model="accommodation"
                  passRow={rowTriggerAction}
                />
            </Box>
        </Box>
    )
}

export default AccomodationTableView

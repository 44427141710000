import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { Airline } from '../../../interfaces/Airline';
import AirlineService from '../../../services/Entities/AirlineService';
import AirportService from '../../../services/Entities/AirportService';


const AddEditFlightForm = ({
    object,
    handleInputChange,
}: any) => {

    const [airportLoading, setAirportLoading] = React.useState<boolean>(false);
    const [airlineLoading, setAirlineLoading] = React.useState<boolean>(false);
    const airportClient = new AirportService();

    const [airports, setAirports] = React.useState<any>([]);
    const [airlines, setAirlines] = React.useState<Airline | any>([]);

    const airlineClient = new AirlineService();

    const getAirports = () => {
        setAirportLoading(true)
        airportClient
            .getAllResources({})
            .then((res) => {
                setAirports(res["data"]["data"]);
            })
            .finally(() => setAirportLoading(false));
    }

    const getAirlines = () => {
        setAirlineLoading(true);
        airlineClient
            .getAllResources({})
            .then((res) => {
                setAirlines(res["data"]["data"]);
            })
            .finally(() => setAirlineLoading(false))
    }

    React.useEffect(() => {
        getAirports();
        getAirlines();
    }, []);

    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField fullWidth
                        label="Flight Number"
                        name="code"
                        value={object ? object.code : ""}
                        onChange={(e) => handleInputChange(e)}
                        className="my-2" />
                </Grid>
                {/* Format: yyyy-mm-ddThh:mm */}
                {/* Date that comes in: yyyy-mm-ddThh:mm:ssZ */}
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField fullWidth
                        name="boarding_time"
                        type="datetime-local"
                        label="Boarding Time"
                        value={object ? object.boarding_time : ""}
                        onChange={(e) => handleInputChange(e)}
                        className="mt-2" />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField fullWidth
                        name="arrival_time"
                        type="datetime-local"
                        label="Arrival Time"
                        value={object ? object.arrival_time : ""}
                        onChange={(e) => handleInputChange(e)}
                        className="mt-2" />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField fullWidth
                        name="takeoff_time"
                        type="datetime-local"
                        label="Take-Off Time"
                        value={object ? object.takeoff_time : ""}
                        onChange={(e) => handleInputChange(e)}
                        className="mt-2" />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                    {(airportLoading) ? <CircularProgress /> : (
                        <>
                        <InputLabel id="from_label">From</InputLabel>
                        <Select
                            name="from"
                            defaultValue={object?.from_id ? object?.from_id : airports[0]}
                            onChange={(e) => handleInputChange(e)}>
                            {
                                airports.map((airport: Airline, index: number) => (
                                    <MenuItem key={index} value={airport.id}>{airport.name}</MenuItem>
                                ))
                            }
                        </Select>
                        </>
                    )}
                    </FormControl>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                    {(airportLoading) ? <CircularProgress /> : (
                        <>
                        <InputLabel id="to_label">Destination</InputLabel>
                        <Select
                            labelId="to_label"
                            name="to"
                            defaultValue={object?.to_id ? object?.to_id : airports[0]}
                            onChange={(e) => handleInputChange(e)}>
                            {
                                airports.map((airport: Airline, index: number) => (
                                    <MenuItem key={index} value={airport.id}>{airport.name}</MenuItem>
                                ))
                            }
                        </Select>
                        </>
                    )}
                    </FormControl>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                        {(airlineLoading) ? <CircularProgress /> :
                            <>
                                <InputLabel id="airline_label">Airlines</InputLabel>
                                <Select
                                    variant="standard"
                                    name="airline"
                                    labelId="airline_label"
                                    defaultValue={object?.airline_id ? object.airline_id : airlines[0]}
                                    onChange={(e) => handleInputChange(e)}>
                                    {airlines.map((airline: Airline, index: number) => (
                                        <MenuItem key={index} value={airline.id}>{airline.name}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        }
                    </FormControl>
                </Grid>


                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="status_label">Status</InputLabel>
                        <Select fullWidth
                            labelId="status_label"
                            name="status"
                            value={object?.status ? object.status : "BOARDING"}
                            onChange={(e) => handleInputChange(e)}>
                            {["BOARDING", "DELAYED", "CANCELLED", "ON TIME"].map((status: string, index: number) => (
                                <MenuItem key={index} value={status}>{status}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField fullWidth
                        label="Gate"
                        name="gate"
                        value={object ? object.gate : ""}
                        onChange={(e) => handleInputChange(e)}
                        className="my-2" />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField fullWidth
                        label="Terminal"
                        name="terminal"
                        value={object ? object.terminal : ""}
                        onChange={(e) => handleInputChange(e)}
                        className="my-2" />
                </Grid>
            </Grid>
        </form>
    )

}

export default AddEditFlightForm;
import { applyMiddleware, createStore } from "redux";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const persistConfig = {
    key: "booking",
    storage,
    stateReconciler: autoMergeLevel2,
    version: 1,
  };
  // @ts-ignore
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  export const store = createStore(
    persistedReducer,
    applyMiddleware(thunk)
  );
  export const persistor = persistStore(store);
  
import { Box, Card, Typography, CircularProgress } from "@mui/material";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomButton from "../../../components/CustomButton/CustomButton";
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import FacilitiesForm from "../../../components/PageComponents/Facilities/FacilitiesForm";
import AddEditFlightForm from '../../../components/PageComponents/Flights/AddEditFlightForm';
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import { Facility } from "../../../interfaces/Facility";
import FacilitiesService from "../../../services/Entities/FacilitiesService";
import FlightService from "../../../services/Entities/FlightService";
import { showErrorAlert, showSuccessAlert } from '../../../store/actions/alerts';
import "../../Flights/AddEditFlight/AddEditFlight.scss";

interface facilityFormProps {
    facility: Facility,
    fetchAll: () => void,
    onClose: () => void
}

const AddEditFacilities = (facilityProp: facilityFormProps) => {
    const [formState, setFormState] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [facility, setFacility] = React.useState<Facility>(facilityProp.facility ? facilityProp.facility : { name: "", icon_url: "", id: "" });
    const facilitiesService = new FacilitiesService();
    const history = useHistory();
    const dispatch = useDispatch();

    const fetchSpecificResource = () => {
        setLoading(true);
        facilitiesService
            .getSpecificResource(facilityProp.facility.id ? facilityProp.facility.id : "")
            .then((res) => {
                setFacility(res.data.data)
                setFormState("edit");
            })
            .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        if (facilityProp.facility.id) {
            setFormState("edit");
            fetchSpecificResource();
        }
        else
            setFormState("create");
    }, [facilityProp.facility.id]);

    const submitResource = () => {
        setLoading(true);
        if (formState === "create") {
            facilitiesService
                .postResource(facility)
                .then((res) => {
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    facilityProp.fetchAll();
                    facilityProp.onClose();
                })
                .finally(() => setLoading(false));
        } else {
            delete facility.accommodations;
            delete facility.flights;
            facilitiesService
                .patchResource(facilityProp.facility.id ? facilityProp.facility.id : "", facility)
                .then((res) => {
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    facilityProp.fetchAll();
                    facilityProp.onClose();
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <>
            <Box sx={{p: 3}}>
              <Typography variant="h1" sx={{mb: 4, color: "#333"}}>{formState === "create" ? "Add" : "Edit"} Facility</Typography>
                <Card sx={{p: 3, boxShadow: "none", border: "0.7px #ccc solid"}}>
                        <React.Fragment>
                            <FacilitiesForm
                                loading={loading}
                                setFacility={setFacility}
                                facility={facility}/>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%"
                                }}>
                                <CustomButton 
                                    style={{width: "25%", marginTop: "20px"}}
                                    onClick={submitResource}
                                    disabled={!facility?.name || !facility.icon_url}>
                                    {loading ? <CircularProgress style={{ color: "#fff" }} /> : "SAVE"}
                                </CustomButton>
                            </Box>
                        </React.Fragment>
                </Card>

            </Box>
        </>
    )
}


export default AddEditFacilities

import React from 'react'
import AccommodationPhotoAlbum from '../../../components/PageComponents/Accomodation/AccommodationPhotoAlbum/AccommodationPhotoAlbum';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import GenericHeader from '../../../components/GenericHeader/GenericHeader';
import useStoreSessionSelector from '../../../hooks/useStoreSessionSelector';
import { showErrorAlert, showSuccessAlert } from '../../../store/actions/alerts';

import "../../../pages/Accomodation/AddEditAccomodation/AddEditAccomodation.scss";
import AddEditAdsForm from '../../../components/PageComponents/Ads/AddEditAdsForm';
import AdsService from '../../../services/Entities/AdsService';
import { Box, Card, Typography, CircularProgress, Skeleton } from '@mui/material';
import CustomButton from '../../../components/CustomButton/CustomButton';
const AddEditAdvertising = () => {
    const [formState, setFormState] = React.useState<string>("");
    const [object, setObject] = React.useState<any>(undefined);
    const [patchObject, setPatchObject] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [apiPhotos, setApiPhotos] = React.useState<any[]>([]);
    const [patchPhotos, setPatchPhotos] = React.useState<any[]>([]);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const adsClient = new AdsService();
    const { id }: any = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    React.useEffect(() => console.log("patchObject", patchObject), [patchObject]);

    const deleteResourceHandler = () => {
        if (!id) {
            dispatch(showErrorAlert({ message: "There is no accommodation to delete! You are in create-mode." }));
        }
        else {
            adsClient
                .deleteResource(id)
                .then(() => history.push("/accomodations"))
                .finally(() => setLoading(false));
        }
    }

    const fetchSpecificResource = () => {
        setLoading(true);
        adsClient
            .getSpecificResource(id)
            .then((res) => {
                setObject(res.data.data);
                setApiPhotos(res.data.data?.photos);
            })
            .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        if (id) {
            setFormState("edit");
            fetchSpecificResource();
        }
        else
            setFormState("create");
    }, [id]);


    const submitResource = () => {
        let tempForm = {...patchObject};
        !tempForm.photos.length ? delete tempForm.photos : console.log("photos submitted", tempForm.photos);
        setLoading(true);
        if (formState === "create") {
            adsClient
                .postResource({...patchObject})
                .then((res) => {
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    history.push("/ads/" + res["data"]["data"]["id"]);
                    setSubmitted(true);
                    setPatchObject({});
                })
                .finally(() => setLoading(false));
        } else {
            adsClient
                .patchResource(id, {...patchObject})
                .then((res) => {
                    dispatch(showSuccessAlert("Submitted Successfully!"));
                    setObject(res["data"]["data"]);
                    fetchSpecificResource();
                    setSubmitted(true);
                    setPatchObject({});
                })
                .finally(() => setLoading(false));
        }
    }

    const handleInputChange = (e: any) => {
        if (e?.target?.name) {
            setObject({...object, [e.target.name]: e?.target?.value});
        }
        if(e.target?.checked){
            setObject({...object, [e.target.name]: e?.target?.checked});
        }
    }

    React.useEffect(() => {
        setPatchObject({...patchObject, photos: {create: patchPhotos}});
    }, [patchPhotos]);

    React.useEffect(() => setPatchObject({...object}), [object])

    return (
        <React.Fragment>
            <GenericHeader deleteResourceHandler={deleteResourceHandler}
                model="accomodation" />
            <Box sx={{p: 3}}>
              <Typography variant="h1" sx={{mb: 4, color: "#333"}}>{formState === "create" ? "Add" : "Edit"} Advertisement</Typography>
                <Card sx={{p: 3}}>
                            <AddEditAdsForm
                                object={object}
                                setObject={setObject}
                                setPatchPhotos={setPatchPhotos}
                                setApiPhotos={setApiPhotos}
                                handleInputChange={handleInputChange}
                                loading={loading}
                                apiPhotos={apiPhotos}
                                setPatchObject={setPatchObject}
                                patchObject={patchObject}
                                formState={formState}
                                patchPhotos={patchPhotos}
                                submitted={submitted}
                                setSubmitted={setSubmitted}/>
                            <Box
                             sx={{display: "flex",
                                  justifyContent: "center",
                                  width: "100%"}}>
                                <CustomButton
                                    style={{marginTop: "15px", width: "25%"}}
                                    onClick={submitResource}
                                    disabled={!object?.title || !object?.label || loading}>
                                    {loading ? <CircularProgress style={{ color: "#fff" }} /> : "SAVE"}
                                </CustomButton>
                            </Box>
                </Card>

            </Box>
        </React.Fragment>
    )
}

export default AddEditAdvertising

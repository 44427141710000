import { faPlus, faSearch, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDebounce } from "use-debounce";
import useScreenSize from "../../hooks/useScreenSize";
import CustomButton from "../CustomButton/CustomButton";
import { ConfirmDialog } from "../Dialogs";
import GoBackButton from "../GoBackButton/GoBackButton";
import PageHeader from "../PageHeader";
import "./GenericHeader.scss";

interface GenericHeaderType {
  selectedRows?: any[],
  onSearch?: any,
  model?: string,
  addResourceHandler?: any,
  deleteResourceHandler?: any,
  uploadResourceHandler?: any
}

const GenericHeader = (params: GenericHeaderType) => {
  const [query, setQuery] = useState("");
  const [value] = useDebounce(query, 1000);
  const device = useScreenSize();
  
  const initConfirmConfig = {
    title: "",
    body: "",
    visible: false,
  };

  React.useEffect(() => {
    if(params.onSearch){
      params.onSearch(value);
    }
  }, [value]);

  const [confirmConfig, setConfirmConfig] = useState(initConfirmConfig);

  const confirmDelete = () => {
    setConfirmConfig({
      title: "Please Confirm" || '',
      body: `Are you sure you want to delete this ${params.model}?` || '',
      visible: true,
    });
  };


  return (
    <PageHeader>
      <>
        <Box className="searchBox">
          <GoBackButton />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faSearch} className="searchButton" color="primary" />
                    </InputAdornment>
                  ),
                }}
                className="outlinedInput"
                fullWidth
                placeholder="Search..."
                variant="outlined"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </Box>
        </Box>
          <Box className="actionsBox" style={device === "mobile" ? {display: "flex"} : {}}>
            <ConfirmDialog
              setConfirmConfig={setConfirmConfig}
              confirmConfig={confirmConfig}
              executeActionCallback={params.deleteResourceHandler}
            />
            {params.uploadResourceHandler && (
              <CustomButton className="iconButton" onClick={params.uploadResourceHandler}>
                <FontAwesomeIcon icon={faUpload} className="icon"/>
              </CustomButton>
            )}
            {params.addResourceHandler && (
              <CustomButton className="iconButton" onClick={params.addResourceHandler}>
                <FontAwesomeIcon icon={faPlus} className="icon"/>
              </CustomButton>
            )}
            {params.deleteResourceHandler && (
              <CustomButton
                className="iconButton"
                onClick={confirmDelete}
                disabled={params.selectedRows && !params.selectedRows?.length}
              >
                <FontAwesomeIcon icon={faTrash} className="icon" />
              </CustomButton>
            )}
            
          </Box>
      </>
    </PageHeader>
  );
};

export default GenericHeader;
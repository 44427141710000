import { Route } from "../interfaces/Route";
import AccomodationTableView from "../pages/Accomodation/AccomodationTableView/AccomodationTableView";
import AddEditAccomodation from "../pages/Accomodation/AddEditAccomodation/AddEditAccomodation";
import AddEditAdvertising from "../pages/Advertising/AddEditAdvertising/AddEditAdvertising";
import AdsTableView from "../pages/Advertising/AdsTableView/AdsTableView";
import Dashboard from "../pages/Dashboard/Dashboard";
import FacilitiesTableView from "../pages/Facilities/FacilitiesTableView/FacilitiesTableView";
import AddEditFlight from "../pages/Flights/AddEditFlight/AddEditFlight";
import FlightsTableView from "../pages/Flights/FlightsTableView/FlightsTableView";
import AddEditUser from "../pages/Users/AddEditUser/AddEditUser";
import UsersTableView from "../pages/Users/UsersTableView/UsersTableView";

export const privateRoutes: Route[] = [
    {exact: true, path: "/dashboard", component: Dashboard},
    {exact: true, path: "/users", component: UsersTableView},
    {exact: true, path: "/users/add", component: AddEditUser},
    {exact: true, path: "/users/:id", component: AddEditUser},
    {exact: true, path: "/flights", component: FlightsTableView},
    {exact: true, path: "/flights/add", component: AddEditFlight},
    {exact: true, path: "/flights/:id", component: AddEditFlight},
    {exact: true, path: "/accomodations", component: AccomodationTableView},
    {exact: true, path: "/accomodations/add", component: AddEditAccomodation},
    {exact: true, path: "/accomodations/:id", component: AddEditAccomodation},
    {exact: true, path: "/facilities", component: FacilitiesTableView},
    {exact: true, path: "/ads", component: AdsTableView},
    {exact: true, path: "/ads/add", component: AddEditAdvertising},
    {exact: true, path: "/ads/:id", component: AddEditAdvertising}
]
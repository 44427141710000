export interface bookingState {
  session: {
    token: string
  },
  alerts: {
    success: {
      message: string,
      open: boolean
    },
    error: {
      message: string,
      open: boolean
    }
  },
  qr: {
    imgUrl: string,
    email: string,
    password: string
  }
}

const initialState: bookingState = {
  session: {
    token: "",
  },
  alerts: {
    success: {
      message: "",
      open: false,
    },
    error: {
      message: "",
      open: false,
    },
  },
  qr: {
    imgUrl: "",
    email: "",
    password: ""
  }
};

export default initialState;

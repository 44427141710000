import { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import axiosFactory from "../../services/AxiosInstance";
import AuthenticationService from "../../services/Entities/AuthenticationService";

export const START_SESSION = "START_TRADE_API_SESSION";
export const END_SESSION = "END_TRADE_API_SESSION";

export const startSession = (token: any, history: any) => {

  return async (dispatch: any) => {
    if (!token) return;

    const action = {
      type: START_SESSION,
      payload: token,
    };
    postActionLogin(token, history);
    dispatch(action);
  };
};

export const endSession = () => {
  return async (dispatch: any) => {
    const action = {
      type: END_SESSION,
    };
    dispatch(action);
  };
};

export const logout = () => {
  return async (dispatch: any) => {
    dispatch(endSession());
    sessionStorage.removeItem("ACCESS_TOKEN");
    sessionStorage.removeItem("USER_INFO");
    const action = {
      type: END_SESSION,
    };
    dispatch(action);
  };
};


export const postActionLogin = async (token: string, history: any) => {
  if (token) {
    sessionStorage.setItem("ACCESS_TOKEN", token);
    sessionStorage.setItem("USER_INFO", JSON.stringify(jwtDecode(token)));
    const axiosInst = axiosFactory();
    const userData = await axiosInst.get("auth/me", {headers: {Authorization: `Bearer ${token}`}})
    sessionStorage.setItem("USER_INFO", JSON.stringify(userData.data.result));
    history.push("/");
  }
}

export const loginUser = (payload: any, setLoading: any, history: any, getImgUrl: any, detectErr: any) => {
  return async (dispatch: any) => {
    try {
      const authApi = new AuthenticationService();
      const responseData = await authApi.postSpecificResource("login", payload);
      if (responseData.data.token)
      {
        dispatch(startSession(responseData.data.token, history));
      }
      else {
        getImgUrl(responseData.data.qrCode);
        detectErr(false);
      }
      setLoading(false);
    } 
    catch (e: AxiosError | any) {
      setLoading(false);
      detectErr(true);
    }
  };
};
